import { createSlice } from '@reduxjs/toolkit';
import { httpPostWithCsrfToken } from '@/utils/Http';
import Cookies from 'js-cookie';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        favouriteCollections: [],
        favouriteResources: [],
        selectedSection: null,
        currentSection: null,
        customerId: null,
        expired: null,
        expiryDate: null,
        loggedIn: false,
        organisation: {},
    },
    reducers: {
        setUser: (state, action) => {
            state.id = action.payload.id;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.favouriteCollections = action.payload.favouriteCollections;
            state.favouriteResources = action.payload.favouriteResources;
            state.selectedSection = action.payload.selectedSection;
            state.customerId = action.payload.customerId;
            state.expired = action.payload.expired;
            state.expiryDate = action.payload.expiryDate;
            if (state.currentSection == null) {
                state.currentSection = action.payload.selectedSection;
            }
            state.organisation = action.payload.organisation;
            // Cookies.set('user', action.payload.expired);
        },
        clearUser: (state) => {
            state.id = null;
            state.firstName = null;
            state.lastName = null;
            state.email = null;
            state.favouriteCollections = [];
            state.favouriteResources = [];
            state.selectedSection = null;
            state.currentSection = null;
            state.customerId = null;
            state.expired = null;
            state.expiryDate = null;
            state.organisation = {};
            Cookies.remove('user');
        },
        setUserVar: (state, action) => {
            state[action.payload.name] = action.payload.value;
        },
        addFavouriteCollections: (state, action) => {
            state.favouriteCollections.push(action.payload);
        },
        removeFavouriteCollections: (state, action) => {
            state.favouriteCollections = state.favouriteCollections.filter((collection) => collection.id !== action.payload);
        },
        addFavouriteResources: (state, action) => {
            state.favouriteResources.push(action.payload);
        },
        removeFavouriteResources: (state, action) => {
            state.favouriteResources = state.favouriteResources.filter((resource) => resource.id !== action.payload);
        },
        toggleFavouriteCollection: (state, action) => {
            if (state.favouriteCollections.includes(action.payload)) {
                state.favouriteCollections = state.favouriteCollections.filter((collection) => collection !== action.payload);
                httpPostWithCsrfToken(`${process.env.NEXT_PUBLIC_WAGTAIL_API_URL}/v1/favourite_item/destroy`, { item_id: action.payload });
            } else {
                httpPostWithCsrfToken(`${process.env.NEXT_PUBLIC_WAGTAIL_API_URL}/v1/favourite_item/create`, {
                    item_id: action.payload,
                    item: 'collection',
                });
                state.favouriteCollections.push(action.payload);
            }
        },
        toggleFavouriteResource: (state, action) => {
            if (state.favouriteResources.includes(action.payload)) {
                state.favouriteResources = state.favouriteResources.filter((resource) => resource !== action.payload);
                httpPostWithCsrfToken(`${process.env.NEXT_PUBLIC_WAGTAIL_API_URL}/v1/favourite_item/destroy`, { item_id: action.payload });
            } else {
                httpPostWithCsrfToken(`${process.env.NEXT_PUBLIC_WAGTAIL_API_URL}/v1/favourite_item/create`, {
                    item_id: action.payload,
                    item: 'resource',
                });
                state.favouriteResources.push(action.payload);
            }
        },
        setSelectedSection: (state, action) => {
            state.selectedSection = action.payload;
        },
        setCurrentSection: (state, action) => {
            state.currentSection = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setUser,
    clearUser,
    setUserVar,
    addFavouriteCollections,
    removeFavouriteCollections,
    addFavouriteResources,
    removeFavouriteResources,
    toggleFavouriteCollection,
    toggleFavouriteResource,
    setSelectedSection,
    setCurrentSection,
} = userSlice.actions;

export default userSlice.reducer;
